import { handleCarousel } from './plugins/carouselOpinions';
import { handleSubmitForm } from './plugins/handleSubmitForm';
import { handleToTop } from './plugins/toTop';
import { handleLang } from './plugins/lang';

(function( $ ) {
  $(document).ready(function() {
    $('body').on('scroll', function() {
      $(':focus').blur();
    });
  });
  window.addEventListener('DOMContentLoaded', function () {
    handleCarousel();
    handleSubmitForm();
    handleCookieConsent();
    handleToTop();
    handleLang();

    $('.popup-gallery').magnificPopup({
      delegate: "a[rel='lightbox']",
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          return item.el.attr('title');
        }
      }
    });

    $('#menu-button').on('click', function (event) {
      event.preventDefault();
      $('nav ul.menu').toggle();
      $('nav ul.sub-menu').hide();
      $('#menu-button').toggleClass('close');
      $('header').toggleClass('cover');
      $('#lang-icon').toggleClass('cover');
    });

    $(window).resize(function(){
      if(window.innerWidth > 992) {
        $('nav ul.menu').show();
        $('nav ul.sub-menu').hide();
        $('header').removeClass('cover');
        $('#lang-icon').removeClass('cover');
        $('#menu-button').addClass('close');
      } else {
        $('nav ul.menu').hide();
        $('nav ul.sub-menu').hide();
        $('header').removeClass('cover');
        $('#lang-icon').removeClass('cover');
        $('#menu-button').removeClass('close');
      }
    });

  });
})( jQuery );

function setCookie(cname, cvalue, exdays) {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires="+currentDate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  const name = cname + "=";
  const cookies = document.cookie.split(';');
  for(let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

function handleCookieConsent() {
  // Cookie consent
  if (!getCookie('cookieConsent')) {
    $('#cookie-consent').show();
  }

  document.querySelector('.cookie-consent-btn').addEventListener('click', function (event) {
    console.log('enter cookie');
    event.preventDefault();
    setCookie('cookieConsent', 1, 365);
    $('#cookie-consent').hide();
  })

  document.querySelector('.cookie-consent-btn2').addEventListener('click', function (event) {
    console.log('enter cookie 2');
    event.preventDefault();
    setCookie('cookieConsent', 1, 365);
    $('#cookie-consent').hide();
  })
}
