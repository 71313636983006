export const handleCarousel = () => {
    const carousel = document.querySelector('.opinions-home');

    if (carousel) {
        $('.opinions-content').slick({
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            mobileFirst: true,
            focusOnSelect: true,
            nextArrow: $('.opinions-button-next'),
            prevArrow: $('.opinions-button-prev'),
        });
    }
}